<template>
  <main>
    <CCard>
      <CCardHeader>
        <CRow class="d-flex align-items-center">
          <CCol col="6">
            <h5 class="mb-0">{{ $t('Navigation.Report/OrderEngagement') }}</h5>
          </CCol>
          <CCol col="6" class="text-md-right">
            <CButtonGroup class="mr-2">
              <CButton v-for="Type in ViewTypeOptions" :key="Type.value" size="sm" :color="(ViewType === Type.value ? 'danger' : 'light')"  @click="ViewType = Type.value">{{ Type.label }}</CButton>
            </CButtonGroup>
            <CDropdown size="sm" color="info" placement="bottom-start" class="d-inline-block">
              <template #toggler-content>
                <CIcon size="sm" name="cil-applications-settings" class="mr-1" />{{ $t('Report.Action') }}
              </template>
              <CDropdownItem @click="Print()">
                {{ $t('Report.PrintCSV') }}
              </CDropdownItem>
            </CDropdown>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardHeader class="w-100 overflow-auto px-2">
        <CRow class="text-nowrap">
          <CCol col="12">
            <CButton color="white" shape="pill" class="mr-2" @click="OpenFilter('All')">
              <CIcon name="cil-filter"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2 d-none d-md-inline-block" @click="OpenFilter('DataTimeRange')">
              {{ $t('Order.Filter.DataTime') }}: {{
                SearchFilter.StartTime ? $dayjs(SearchFilter.StartTime).format('YYYY-MM-DD HH:mm:ss') : '--'
              }} ~ {{ SearchFilter.EndTime ? $dayjs(SearchFilter.EndTime).format('YYYY-MM-DD HH:mm:ss') : '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Site')">
              商店: {{ SearchFilter.Site.map(item => item.label).join(',') || '所有' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton v-if="SearchFilter.Site.map(item => item.value).includes($store.state.user.permission.Project)" size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Origin')">
              結帳來源頁面: {{ SearchFilter.Origin.map(item => item.label).join(',') || '所有' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Country')">
              {{ $t('Order.Country') }}: {{ SearchFilter.Country.map(item => item.label).join(',') || '所有' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="danger" shape="pill" class="mr-2" @click="ResetState()">
              <CIcon size="sm" name="cil-x"/>
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardHeader class="w-100 overflow-auto px-2">
        <CRow class="text-nowrap" style="min-width: 900px;">
          <CCol col>
            <CCallout color="warning" class="my-1">
              <small class="text-muted">訂單數量總計</small><br>
              <strong class="h4">{{ TotalStatistics.TotalItems || '0' }}</strong>
            </CCallout>
          </CCol>
          <CCol col>
            <CCallout color="success" class="my-1">
              <small class="text-muted">訂單金額總計</small><br>
              <strong class="h4">{{ $store.state.currency + '$' }}{{ $amount.format(TotalStatistics.TotalAmount || 0) }}</strong>
            </CCallout>
          </CCol>
          <CCol col>
            <CCallout color="info" class="my-1">
              <small class="text-muted">平均訂單總金額</small><br>
              <strong class="h4">{{ $store.state.currency + '$' }}{{ $amount.format(TotalStatistics.AverageTotalOrderAmount || 0) }}</strong>
            </CCallout>
          </CCol>
          <CCol col>
            <CCallout color="danger" class="my-1">
              <small class="text-muted">平均顧客消費力</small><br>
              <strong class="h4">{{ $store.state.currency + '$' }}{{ $amount.format(TotalStatistics.AverageTotalMemberAmount || 0) }}</strong>
            </CCallout>
          </CCol>
          <CCol col>
            <CCallout color="primary" class="my-1">
              <small class="text-muted">顧客平均下單總數</small><br>
              <strong class="h4">{{ TotalStatistics.AverageTotalMemberItems || '0' }}</strong>
            </CCallout>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody v-if="ViewType === 'Chart'">
        <CRow>
          <CCol col="12" lg="6" class="mb-4">
            <CChartHorizontalBar :datasets="ChartDataSet" :labels="ChartLabels" />
          </CCol>
          <CCol col="12" lg="6" class="mb-4">
            <CChartPolarArea
              :datasets="DoughnutData"
              :options="ChartOptions"
              :labels="ChartPaymentLabels"
            />
          </CCol>
          <CCol col="12" lg="6" class="mb-4">
            <CChartHorizontalBar :datasets="ChartDataSet2" :labels="ChartLabels2" />
          </CCol>
          <CCol col="12" lg="6" class="mb-4">
            <CChartHorizontalBar :datasets="ChartDataSet3" :labels="ChartLabels3" />
          </CCol>
        </CRow>
      </CCardBody>
      <CCardBody v-if="ViewType === 'Table'" class="p-0">
        <CDataTable
          id="OrderSaleReport"
          :fields="Field"
          :items="Reporting"
          :loading="Loading"
          :noItemsView="noItemsView"
          addTableClasses="small text-nowrap"
          responsive
          hover
        >
          <template #AverageTotalOrderAmount="{item}">
            <td>
              {{ $store.state.currency }}${{ $amount.format(item.AverageTotalOrderAmount) }}
            </td>
          </template>
          <template #AverageTotalMemberAmount="{item}">
            <td>
              {{ $store.state.currency }}${{ $amount.format(item.AverageTotalMemberAmount) }}
            </td>
          </template>
          <template #TotalAmount="{item}">
            <td>
              {{ $store.state.currency }}${{ $amount.format(item.TotalAmount) }} ({{ item.TotalItems }})
            </td>
          </template>
          <template #PaymentAmount="{item}">
            <td>
              {{ $store.state.currency }}${{ $amount.format(item.PaymentAmount) }} ({{ item.PaymentItems }})
            </td>
          </template>
          <template #UnPaymentAmount="{item}">
            <td>
              {{ $store.state.currency }}${{ $amount.format(item.UnPaymentAmount) }} ({{ item.UnPaymentItems }})
            </td>
          </template>
          <template #ReturnPaymentAmount="{item}">
            <td>
              {{ $store.state.currency }}${{ $amount.format(item.ReturnPaymentAmount) }} ({{ item.ReturnPaymentItems }})
            </td>
          </template>
        </CDataTable>
      </CCardBody>
      <CCardFooter>
      </CCardFooter>
    </CCard>
    <CModal id="FilterModel" class="ActionModel" :show.sync="FilterModel" :centered="true" :closeOnBackdrop="!Submit">
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'DataTimeRange'" form class="form-group mb-0">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.StartTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.StartTime" mode="dateTime" is24hr :max-date="$dayjs(SearchFilter.EndTime || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD HH:mm" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.EndTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.EndTime" mode="dateTime" is24hr :min-date="$dayjs(SearchFilter.StartTime || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD HH:mm" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Site'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Site') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Site"
            v-model="SearchFilter.Site"
            label="label"
            track-by="value"
            :searchable="false"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="$store.getters.storeList"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Origin'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Origin') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Origin"
            v-model="SearchFilter.Origin"
            label="label"
            track-by="value"
            :searchable="false"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="PageList"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Country'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Country') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Country"
            v-model="SearchFilter.Country"
            label="label"
            track-by="value"
            :searchable="false"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="CountryOptions"
            :multiple="true"
            :taggable="true"
            @select="ChangeCountry"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CInput v-if="ShowFilter === 'All'" :label="$t('Order.UTM.utm_id')" type="text" v-model="SearchFilter.utm_id" horizontal />
      <CInput v-if="ShowFilter === 'All'" :label="$t('Order.UTM.utm_source')" type="text" v-model="SearchFilter.utm_source" horizontal />
      <CInput v-if="ShowFilter === 'All'" :label="$t('Order.UTM.utm_medium')" type="text" v-model="SearchFilter.utm_medium" horizontal />
      <CInput v-if="ShowFilter === 'All'" :label="$t('Order.UTM.utm_campaign')" type="text" v-model="SearchFilter.utm_campaign" horizontal />
      <CInput v-if="ShowFilter === 'All'" :label="$t('Order.UTM.utm_term')" type="text" v-model="SearchFilter.utm_term" horizontal />
      <CInput v-if="ShowFilter === 'All'" :label="$t('Order.UTM.utm_content')" type="text" v-model="SearchFilter.utm_content" horizontal />
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Filter') }}</h5>
          <CButtonClose @click="FilterModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#FilterModelSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="FilterModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="FilterModelSubmit" @click="Init()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
{
"meta": {
"label": "顧客參與統計"
}
}
</route>

<script>
import {CChartHorizontalBar, CChartPolarArea} from '@coreui/vue-chartjs'

export default {
  name: 'OrderSaleReport',
  layout: 'manage',
  components: {
    Multiselect: () => import('@/plugins/mutiselect'),
    CChartHorizontalBar,
    CChartPolarArea
  },
  data() {
    return {
      Loading: false,
      FilterModel: false,
      Submit: false,
      ShowFilter: 'All',
      SearchFilter: {
        StartTime: this.$dayjs(this.$dayjs().format('YYYY-MM-01 00:00:00')).unix() * 1000,
        EndTime: this.$dayjs(this.$dayjs().add(1, 'day').format('YYYY-MM-DD 23:59:59')).unix() * 1000,
        Site: [
          {
            value: (this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project),
            label: '網路商店'
          }
        ],
        Origin: [],
        Country: [],
        utm_id: '',
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
        utm_term: '',
        utm_content: ''
      },
      Color: [
        '#4184f3', '#7cb442', '#ff5722', '#ff8f00', '#aa46bb',
        '#25abc0', '#9d9c23', '#5b6abf', '#ef6191', '#10786a',
        '#c11c5a', '#fa937f', '#34c0ff'
      ],
      ViewTypeOptions: [
        {
          value: 'Table',
          label: '表格'
        },
        {
          value: 'Chart',
          label: '圖表'
        }
      ],
      ViewType: 'Table',
      Reporting: [],
      TotalStatistics: {},
      noItemsView: {
        noResults: this.$t('Global.NoResults'),
        noItems: this.$t('Global.NoItems')
      },
      ChartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom'
          }
        }
      }
    }
  },
  computed: {
    PageList() {
      return this.$store.state.landingPageList || []
    },
    CountryOptions() {
      return Object.keys(this.$t('Logistics.CountryOptions')).map(value => {
        return {
          value,
          label: this.$t('Logistics.CountryOptions')[value]
        }
      })
    },
    ChartLabels() {
      return ['平均訂單金額', '顧客消費力']
    },
    ChartLabels2() {
      return ['訂單金額']
    },
    ChartLabels3() {
      return ['顧客平均下單數']
    },
    ChartDataSet() {
      return this.Reporting.map((item, index) => {
        return {
          label: item.Name,
          borderColor: index > 12 ? this.Color[Math.floor(Math.random() * 11)] : this.Color[index],
          backgroundColor: index > 12 ? this.Color[Math.floor(Math.random() * 11)] : this.Color[index],
          fill: false,
          data: [item.AverageTotalOrderAmount,item.AverageTotalMemberAmount],
        }
      })
    },
    ChartDataSet2() {
      return this.Reporting.map((item, index) => {
        return {
          label: item.Name,
          borderColor: index > 12 ? this.Color[Math.floor(Math.random() * 11)] : this.Color[index],
          backgroundColor: index > 12 ? this.Color[Math.floor(Math.random() * 11)] : this.Color[index],
          fill: false,
          data: [item.TotalAmount],
        }
      })
    },
    ChartDataSet3() {
      return this.Reporting.map((item, index) => {
        return {
          label: item.Name,
          borderColor: index > 12 ? this.Color[Math.floor(Math.random() * 11)] : this.Color[index],
          backgroundColor: index > 12 ? this.Color[Math.floor(Math.random() * 11)] : this.Color[index],
          fill: false,
          data: [item.AverageTotalMemberItems],
        }
      })
    },
    ChartPaymentLabels() {
      return this.Reporting.map((item) => item.Name)
    },
    DoughnutData() {
      return [
        {
          backgroundColor: this.Reporting.map((item, index) => index > 12 ? this.Color[Math.floor(Math.random() * 11)] : this.Color[index]),
          data: this.Reporting.map((item) => Math.round(item.TotalItems / this.TotalStatistics.TotalItems * 10000) / 100)
        }
      ]
    },
    Field() {
      return [
        {key: 'Name', label: '顧客類型'},
        // {key: 'TotalItems', label: '訂單筆數'},
        {key: 'TotalAmount', label: '訂單金額(筆數)'},
        {key: 'AverageTotalOrderAmount', label: '平均訂單金額'},
        {key: 'MemberCount', label: '參與顧客數'},
        {key: 'AverageTotalMemberAmount', label: '顧客消費力'},
        {key: 'AverageTotalMemberItems', label: '顧客平均下單數'},
        // {key: 'PaymentItems', label: '已付款筆數'},
        {key: 'PaymentAmount', label: '已付款金額(筆數)'},
        // {key: 'UnPaymentItems', label: '未付款筆數'},
        {key: 'UnPaymentAmount', label: '未付款金額(筆數)'},
        // {key: 'ReturnPaymentItems', label: '已退款筆數'},
        {key: 'ReturnPaymentAmount', label: '已退款金額(筆數)'}
      ]
    }
  },
  mounted() {
    this.$Progress.start()
    return this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      this.FilterModel = false
      return Promise.all([
        this.GetReporting()
      ]).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetReporting() {
      this.Loading = true
      this.FilterModel = false
      const Data = {
        StartTime: this.SearchFilter.StartTime / 1000,
        EndTime: this.SearchFilter.EndTime / 1000,
      }
      if (this.SearchFilter.Site.length > 0) {
        Data.Site = this.SearchFilter.Site.map(item => item.value)
      } else if (this.SearchFilter.Site.length === 0) {
        const Site = (this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project)
        this.SearchFilter.Site = [
          {
            value: Site,
            label: '網路商店'
          }
        ]
        Data.Site = Site
      }
      if (this.SearchFilter.Origin.length > 0) {
        Data['Variable.CartType'] = this.SearchFilter.Origin.map(item => item.value)
      }
      if (this.SearchFilter.Country.length > 0) {
        Data['MemberInfo.Country'] = this.SearchFilter.Country.map(item => item.value)
      }
      const UTMParams = ['utm_id', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
      UTMParams.forEach((item) => {
        if (this.SearchFilter[item] !== '') Data[`Variable.UTM.${item}`] = this.SearchFilter[item]
      })
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/order/reporting/engagement',
          Data
        }
      }).then(({data}) => {
        this.Loading = false
        this.Reporting = data.Data
        this.TotalStatistics = data.TotalStatistics
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    OpenFilter(type = 'All') {
      this.FilterModel = true
      this.ShowFilter = type
    },
    AddSearchFilter(newTag, id) {
      id = id.replace('SearchFilter.', '')
      this.SearchFilter[id].push(newTag.trim())
    },
    ChangeCountry(newTag, id) {
      id = id.replace('SearchFilter.', '')
      const TaiwanCode = ['TW', 'TW-MAIN', 'TW-OUTLYING']
      if (TaiwanCode.includes(newTag.value)) {
        TaiwanCode.splice(TaiwanCode.findIndex(code => code === newTag.value), 1)
        TaiwanCode.forEach((code) => {
          this.SearchFilter[id].push({
            label: this.$t('Logistics.CountryOptions')[code],
            value: code
          })
        })
      }
    },
    ResetState() {
      this.Submit = false
      this.AlertModel = false
      this.SearchFilter = {
        StartTime: this.$dayjs(this.$dayjs().format('YYYY-MM-01')).unix() * 1000,
        EndTime: this.$dayjs().unix() * 1000,
        Site: [
          {
            value: (this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project),
            label: '網路商店'
          }
        ],
        Origin: [],
        Country: []
      }
      this.Init()
    },
    Print() {
      this.Loading = true
      this.FilterModel = false
      const Data = {
        StartTime: this.SearchFilter.StartTime / 1000,
        EndTime: this.SearchFilter.EndTime / 1000
      }
      if (this.SearchFilter.Site.length > 0) {
        Data.Site = this.SearchFilter.Site.map(item => item.value)
      } else if (this.SearchFilter.Site.length === 0) {
        const Site = (this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project)
        this.SearchFilter.Site = [
          {
            value: Site,
            label: '網路商店'
          }
        ]
        Data.Site = Site
      }
      if (this.SearchFilter.Origin.length > 0) {
        Data['Variable.CartType'] = this.SearchFilter.Origin.map(item => item.value)
      }
      if (this.SearchFilter.Country.length > 0) {
        Data['MemberInfo.Country'] = this.SearchFilter.Country.map(item => item.value)
      }
      const UTMParams = ['utm_id', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
      UTMParams.forEach((item) => {
        if (this.SearchFilter[item] !== '') Data[`Variable.UTM.${item}`] = this.SearchFilter[item]
      })
      return this.$store.dispatch('InnerRequest', {
        endPoint: '/import',
        url: '/service/exportReportCSV',
        method: 'post',
        data: {
          URL: '/order/reporting/engagement',
          Data,
          type: 'engagement'
        }
      }).then((response) => {
        this.Loading = false
        const BinaryString = window.atob(response.data)
        const Bytes = new Uint8Array(BinaryString.length)
        for (let index = 0; index < BinaryString.length; index++) {
          Bytes[index] = BinaryString.charCodeAt(index)
        }
        const Data = new Blob([Bytes], {type: 'application/octet-stream'})
        const blobURL = URL.createObjectURL(Data)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', `顧客參與統計-${this.$dayjs().format('YYYYMMDD')}.csv`, '_blank')
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        window.URL.revokeObjectURL(blobURL)
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    }
  }
}
</script>

<style lang="scss">
#OrderSaleReport tbody td:first-child {
  vertical-align: middle;
}

.WidgetList {
  .card-body {
    small {
      height: 36px;
      max-height: 36px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
